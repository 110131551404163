import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './pages/Home'
import Ilustations from './pages/Ilustations'
import Layout from './components/layout/Layout'
import Animations from './pages/Animations'
import About from './pages/About'
import axios from 'axios'
import AccountModel from './api/model/Account'
import { useEffect, useState } from 'react'

function App(): JSX.Element {
  const [account, setAccount] = useState<AccountModel>()

  const getAccount = async () => {
    await axios.get(process.env.REACT_APP_PROXY_HOST + '/api/account?id=1').then((response) => {
      setAccount(response.data)
    })
  }

  useEffect(() => {
    getAccount()
  }, [])

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route path='/' element={<Layout email={account?.email} />}>
          <Route index element={<Home />} />
          <Route path='/ilustrations' element={<Ilustations />} />
          {/* <Route path='/animations' element={<Animations />} /> */}
          <Route path='/about' element={<About />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
